<template>
	<div class="clients">
		<h1>Clients</h1>
		<!-- GET CLIENT FORM -->
        <p v-for="message in formMessages" :key="message">
            {{message}}
        </p>
		<!-- GET MAIN CLIENTS LIST -->
		<ClientsList :key="clientsListKey"></ClientsList>
	</div>
</template>
<script>
import ClientsList from "../../components/lists/ClientsList.vue"

import {mapGetters} from 'vuex';
export default {
	data() {
		return {
			clientsListKey: 1,
		}
	},
	components: {
		ClientsList,
	},
	mounted() {
		this.getUsers()
	},
	computed: {
		isEditClient() {
			return this.$route.name === "Edit Client" || this.$route.name === "Edit Clients"
		},
        ...mapGetters("client", ['getClientSlug'])
	},
	methods: {
        searchUsersByUsername(){
            this.getUsers(this.usernameSearchQuery);
        },
		forceUpdate() {
			this.$forceUpdate()
		},
        async unassignUser(user){
            let confirmed = confirm(`Are you sure you want to unassign ${user.fullName} from this client?`);
            let userIds = [user.id];
            if(confirmed){
                let clientSlug = this.getClientSlug;
                await this.$store.dispatch('removeClientUserRelations', {clientSlug, userIds})
                await this.$store.dispatch("client/fetchClientData", clientSlug)
                this.$emit('userRemoved');
            }
        },
		async getUsers(optionalUsernameSearchQuery = undefined) {
            this.usersArray = [];
			try {
				if (!this.isEditClient) {
					this.usersArray = await this.$store.dispatch("user/getAllUsers")
				} else {
					this.usersArray = [...this.$store.getters["client/getClientUsers"]]
				}
				this.sortUsers(optionalUsernameSearchQuery)
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		sortUsers(optionalUsernameSearchQuery = undefined) {

            // Clear the object
            this.sortedUsers = {};

			//get the first letter of client name
			function findFirstLetter(string) {
				return string.charAt(0)
			}

            // If we are provided an optional search query
            if(optionalUsernameSearchQuery){
                // Filter the usersArray based on a partial string match
                this.usersArray = this.usersArray.filter((user)=>user.username.toLowerCase().includes(optionalUsernameSearchQuery.toLowerCase()));
            }
		
			// Sort by alphabetical order
			let alphabeticalUsersArray = this.usersArray.sort((a, b) => {
				let fa = a.username.toLowerCase(),
					fb = b.username.toLowerCase()
				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})

			//sort array items under each letter
			alphabeticalUsersArray.forEach((user) => {
				let userFirstLetter = findFirstLetter(user.username).toUpperCase()
				if (this.sortedUsers[userFirstLetter]) {
					this.sortedUsers[userFirstLetter] = [...this.sortedUsers[userFirstLetter], user]
				} else {
					this.sortedUsers[userFirstLetter] = [user]
				}
			})
		},
		warn: function (message, event) {
			if (event) {
				event.preventDefault()
			}
			if (confirm(message)) {
				alert("confirmed")
			} else {
				alert("cancelled")
			}
		},
	},
}
</script>
